import React from "react";
import images from "../../../assets";

function BannerBottom() {
  //const images = useImageImport();
  return (
    // <section className="banner-bottom-sec">
    //   <div className="banner-bot-inner container">
    //     <div className="banner-bot-grid">
    //       <div className="banner-bot-box">
    //         <div className="top-title">Hybrid Learning</div>
    //         <div className="bot-para">
    //           Hybrid learning implements synchronous lessons taught
    //           simultaneously in-person and online.
    //         </div>
    //       </div>

    //       <div className="banner-bot-box">
    //         <div className="top-title">Top Materials</div>
    //         <div className="bot-para">
    //           All materials are designed and prepared by a team of highly
    //           qualified and experienced educators.
    //         </div>
    //       </div>

    //       <div className="banner-bot-box">
    //         <div className="top-title">Best Teachers</div>
    //         <div className="bot-para">
    //           Qualified and experienced teachers ensure students enjoy their
    //           studies & achieve their ambitions
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    //   <div className="side-icon">
    //     <img {...images["icon-paper"]} />
    //   </div>
    // </section>
    <div className="prepration_sec">
    <div className="container-fluid">
        <div className="prepration_main">
            <div className="row">
                <div className="col-md-4">
                    <div className="pre_card">
                        <h2>Enhanced Academic Achievement</h2>
                        <p><strong>Preparation For Exams :</strong>Intensive preparation aimed at helping students
                            achieve high distinctions in their subjects</p>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="pre_card">
                        <h2>Preparation for Future Opportunities & Best</h2>
                        <p>Guidance and preparation for academic scholarships, competitions, and other opportunities that recognize high achievement.</p>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="pre_card">
                        <h2>Small Group (Max. 10) & Personalized Instruction</h2>
                        <p>Smaller class sizes allow for personalized feedback and support from experienced teachers, addressing each student's unique strengths and areas for improvement.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
  );
}

export default BannerBottom;
